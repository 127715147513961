@import './colors.scss';

@keyframes loading {
  100% {
    transform: rotate(360deg);
  }
}

button,
.btn,
input[type='submit'] {
  background-color: $blue3;
  outline: 0;
  border: none;
  border-radius: 5px;
  height: 44px;
  color: #fff;
  font-size: 16px;
  transition: 0.2s ease;
  position: relative;
  padding: 0 20px;

  &:after {
    content: '';
    box-sizing: border-box;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    animation: loading 1s infinite linear;
    opacity: 0;

    position: absolute;
    display: inline-block;
    margin: 0 0 -6px -30px;

    border: 2px solid rgba(0, 0, 0, 0.6);
    border-right: 2px solid #fff;

    transition: 0;
  }

  &:hover {
    background-color: darken($blue3, 20%);
    cursor: pointer;
  }

  &.loading {
    // text-indent: -10px;
    background-color: darken($blue3, 20%);

    &:after {
      position: relative;
      margin: 0 0 -6px 15px;
      opacity: 1;
      transition: 0.6s ease;
    }
  }

  &.small {
    height: 30px;
    font-size: 14px;
    padding: 0 12px;

    &.loading {
      text-indent: 0px;
    }

    &:after {
      width: 18px;
      height: 18px;
      margin: 0 0 -4px 10px;
    }
  }

  &.outline {
    background-color: transparent;
    box-shadow: inset 0 0 0 1.5px $blue3;
  }
}
