@import '../../assets/scss/colors.scss';
// https://react-icons.netlify.com/#/search

$preHoverOpacity: 0.5;

.mobile-header {
  background: $app_bg_dark;
  height: 60px;
  width: 100%;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;

  // .left,
  // .right {
  //   width: 50%;
  // }

  @media (max-width: 600px) {
    display: flex;
  }

  .right {
    height: 100%;
  }
}
