@import-normalize;

// Plugin Imports
@import '~antd/dist/antd.css';

@import '../vars.scss';
@import '../colors.scss';
@import '../antd-overrides.scss';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  font-size: 14px;
  line-height: 1.4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

a {
  text-decoration: none;
  color: $blue1;
  font-weight: 600;
}
a > * {
  font-weight: normal;
}

.content {
  p,
  ul,
  a {
    font-size: 13px;
    line-height: 1.6;
  }

  ul {
    margin-left: 2em;
    li {
      list-style-type: disc;
      margin-bottom: 0.75em;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $blue2;
}

p,
ul {
  //font-family: Georgia, 'Times New Roman', Times, serif;
  line-height: 1.6;
}
